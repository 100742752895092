<template>
  <SiteTemplateV2 v-if="clubVersion === 'v2'">
    <MinhaContaV2 />
  </SiteTemplateV2>

  <SiteTemplate v-else>
    <div class="containerx">
      <div class="row">
        <MinhaConta />
      </div>
    </div>
  </SiteTemplate>
</template>

<script>
import SiteTemplate from "@/templates/SiteTemplate.vue";

import MinhaConta from "@/components/configs/MinhaConta.vue";
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();
import ModalUpload from "@/components/ModalUpload.vue";
import MetaService from "@/services/resources/MetaService";
const serviceMeta = MetaService.build();
import myUpload from "vue-image-crop-upload";
import Cookies from "js-cookie";
import notify from "@/services/libs/notificacao";
import MinhaContaV2 from "@/components/V2/configs/MinhaConta.vue";
import SiteTemplateV2 from "@/templates/SiteTemplateV2.vue";

export default {
  components: {
    SiteTemplate,
    MinhaConta,
    ModalUpload,
    "my-upload": myUpload,
    SiteTemplateV2,
    MinhaContaV2,
  },
  data() {
    return {
      email: "",
      membro: "",
      client: {
        width: 0,
      },
      picProfile: null,
      NewPicProfileId: "",
      NewPicProfileUrl: "",
      logoPainel: null,
      NewLogoId: "",
      NewLogoUrl: "",
      color: "#00E4A0",
      swatches: [["#FF0C37"], ["#8b5aff"], ["#00E4A0"], ["#ffa51a"]],
      ratingHome: true,
      firstName: null,
      lastName: null,
      phoneNumber: null,
      show: false,
      params: {},
      headers: {
        Authorization: Cookies.get("auth_greennCourse"),
      },
      imgDataUrl: "",
      urlPost: process.env.VUE_APP_API_HOST + "/member/meta/picture",
      newPassword: null,
      lastPassword: null,
    };
  },
  computed: {
    isMobile() {
      return this.client.width <= 576;
    },
    theme() {
      return this.$store.state.globalSettings.theme;
    },
    clubVersion() {
      return this.$store.state.globalSettings.clubVersion;
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    updatePassword() {
      var data = {
        id: "change-password",
        password: this.lastPassword,
        new_password: this.newPassword,
      };

      serviceMember
        .postID(data)
        .then((resp) => {
          this.newPassword = null;
          this.lastPassword = null;
          notify("sucesso", "Senha Atualizada!");
        })
        .catch((err) => {
          //console.log(err);
          var error = JSON.parse(err.response.data);
          var msg = "";
          for (var indice in error) {
            msg += error[indice][0] + "<br>";
          }
          if (msg !== "") {
            notify("erro", msg);
          }
        });
    },
    toggleShow() {
      this.show = !this.show;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl) {
      //console.log("-------- crop success --------");
      this.picProfile = imgDataUrl;
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      //console.log("-------- upload success --------");
      //console.log(jsonData);
      //console.log("field: " + field);
      this.show = false;
      notify("sucesso", "Foto de Perfil Atualizada!");
      this.$root.$emit("changedprofilepic");
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      //console.log("-------- upload fail --------");
      //console.log(status);
      //console.log("field: " + field);
      notify("erro", "Erro ao Atualizar Foto de Perfil!");
      this.show = false;
    },
    updateDataName() {
      var data = {
        id: "meta",
        first_name: this.firstName,
        last_name: this.lastName,
        cellphone: this.phoneNumber,
      };

      this.$root.$emit("loadOn");
      serviceMember
        .postID(data)
        .then((resp) => {
          //console.log("update name user", resp);
          notify("sucesso", "Dados Atualizados!");
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });

      setTimeout(() => {
        this.getUserLogged();
      }, 2000);
    },
    getUserLogged() {
      this.$root.$emit("loadOn");
      serviceMember
        .read("/meta")
        .then((resp) => {
          //console.log("get user", resp);
          this.firstName = resp.first_name;
          this.lastName = resp.last_name;
          this.phoneNumber = resp.cellphone;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    changedRating() {
      var status = "";
      if (this.ratingHome === true) {
        status = "on";
      } else {
        status = "off";
      }
      let data = {
        id: "rating_home",
        value: status,
      };
      //console.log(data);
      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then((resp) => {
          //console.log("meta rating home update", resp);
          this.$root.$emit("loadOff");
          this.getRating();
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    getRating() {
      this.$root.$emit("loadOn");
      serviceMeta
        .search("keys[]=rating_home")
        .then((resp) => {
          //console.log("meta rating home", resp);
          if (resp.rating_home === null || resp.rating_home === "on") {
            this.ratingHome = true;
          } else {
            this.ratingHome = false;
          }
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    changedColor() {
      var data = {
        id: "main_color",
        value: this.color,
      };
      //console.log(data);
      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then((resp) => {
          //console.log("meta main color update", resp);
          if (this.color !== null) {
            const d = document;
            d.documentElement.setAttribute("data-color", this.color);
            document.documentElement.style.setProperty(
              "--maincolor",
              this.color
            );
            document.documentElement.style.setProperty(
              "--maincolorn",
              this.$func.convertColor(this.color, 210)
            );
            document.documentElement.style.setProperty(
              "--maincolortrans",
              this.color + "0f"
            );
          }
          this.getColor();
          this.$root.$emit("changedmaincolor");
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    getColor() {
      this.$root.$emit("loadOn");
      serviceMeta
        .search("keys[]=main_color")
        .then((resp) => {
          //console.log("meta main color", resp);
          this.color = resp.main_color;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    updateLogoPainel() {
      var data = {
        id: "logo",
        value: this.NewLogoUrl,
      };
      //console.log(data);
      this.$root.$emit("loadOn");
      serviceMeta
        .postID(data)
        .then((resp) => {
          //console.log("meta logo update", resp);
          this.getLogo();
          this.$root.$emit("changedlogo");
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    getLogo() {
      this.$root.$emit("loadOn");
      serviceMeta
        .search("keys[]=logo")
        .then((resp) => {
          //console.log("meta logo", resp);
          this.logoPainel = resp.logo;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    updateProfilePic() {
      var data = {
        id: "meta/picture",
        value: this.NewPicProfileUrl,
      };
      //console.log(data);
      this.$root.$emit("loadOn");
      serviceMember
        .postID(data)
        .then((resp) => {
          //console.log("meta pic profile update", resp);
          this.getPicProfile();
          this.$root.$emit("changedprofilepic");
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    getPicProfile() {
      this.$root.$emit("loadOn");
      serviceMember
        .read("/meta")
        .then((resp) => {
          //console.log("meta pic profile", resp);
          this.picProfile = resp.picture;
          this.$root.$emit("loadOff");
        })
        .catch((err) => {
          //console.log(err);
          this.$root.$emit("loadOff");
        });
    },
    handleResize() {
      this.client.width = window.innerWidth;
    },
    getCurrentSiteAndMember() {
      serviceMember
        .read("/me")
        .then((resp) => {
          //console.log("get site and member", resp);
          this.email = resp.current.currentMember.email;
          this.membro = resp.current.currentMember.created_at;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
    showModalUploadPic() {
      this.$root.$emit("picprofile");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
    showModalUploadLogo() {
      this.$root.$emit("logopainel");
      this.$root.$emit("bv::show::modal", "modal-upload", "#btnShow");
    },
  },
  mounted() {
    this.getCurrentSiteAndMember();
    this.getUserLogged();
    this.getPicProfile();
    this.getLogo();
    this.getColor();
    this.getRating();
    this.$root.$on("newpicprofile", (data) => {
      this.NewPicProfileId = parseInt(data.id);
      this.NewPicProfileUrl = data.url;
      this.updateProfilePic();
    });
    this.$root.$on("newlogopainel", (data) => {
      this.NewLogoId = parseInt(data.id);
      this.NewLogoUrl = data.url;
      this.updateLogoPainel();
    });
  },
};
</script>

<style scoped lang="scss">
.text-color {
  color: var(--fontcolor) !important;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  padding-bottom: 25px;
  border-bottom: 0.5px solid #ededf0;
}

.containerx {
  width: 100%;
}
.text-color-item {
  color: var(--fontcolor) !important;
}

.hide {
  display: none;
}

.textTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: var(--fontcolor);
  padding-bottom: 4px;
}

.spaceConfigPainel {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 53px;
  width: 40em;
  margin-top: 20px;
}

.spaceConfigPainelMobile {
  display: grid;
  grid-template-columns: 1fr;
  gap: 53px;
  margin-top: -20px;
}

.slidebarMobile {
  float: right;
  padding-top: 20px;
}
.container-grid {
  display: grid;
  grid-template-columns: 100px 1fr;
}
.containerx {
  width: 98%;
}

.flex {
  display: flex;
}

.flexProfile {
  display: flex;
  // border-bottom: 0.5px solid #ededf0;
  width: 95%;
  background: #a7a7a70d;
  padding: 15px;
  border-radius: 15px;
}

.spaceColor {
  margin-top: 20px;
  margin-bottom: 20px;
  .text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    color: #000000;
    margin-bottom: 5px;
  }
}

.spaceConfigPainel {
  display: grid;
  gap: 22px;
  grid-template-columns: 1fr 1fr;
  margin-top: 50px;
  width: 100%;
}

.subTitle {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  letter-spacing: 0.2px;
  color: #81858e;
}

.coluna {
  width: 100%;
  // border-left: 0.5px solid #ededf0;
  padding-left: 10px;
}

.btnChangePic {
  height: 45px;
  border-radius: 5px;
  font-family: Montserrat;
  font-style: normal;
  width: 250px;
  font-weight: 600;
  font-size: 14px;
  color: #00e4a0;
  background: rgba(0, 228, 160, 0.05);
  border: 2px dashed #00e4a0;
  padding: 0px 15px;
  margin-top: 40px;
  margin-left: 30px;
}

.spaceDados {
  margin-left: 40px;
  p {
    margin-top: 23px;
    margin-bottom: 23px;
  }
}

.logoPainel {
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.picProfile {
  width: 100px;
  height: 100px;
  // background: rgba(129, 133, 142, 0.2);
  // border: 1px solid #81858E;
  box-sizing: border-box;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  margin-top: 13px;
  img {
    width: 100%;
  }
}

.picProfile.picMobile {
  margin-top: 25px;
}

.width100 {
  width: 100% !important;
}

/* === */
.container-perfil {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background: silver; */
  padding: 45px 44px 70px 48px;
}
.container-perfil-mobile {
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
  /* background: silver; */
  padding: 60px 0px 0px 0px;
}
.side-left {
  /* background: red; */
}
.side-left h4 {
  font-size: 32px;
  font-weight: 700;
}
.side-left p {
  font-size: 16px;
  margin: 5px 0;
}
.side-left span {
  color: #81858e;
  font-size: 14px;
}
.edicao-perfil {
  margin: 40px 0;
  /* background: tomato; */
}
.edicao-perfil-mobile {
  margin: 40px 0;
  width: 100%;
}
.box-perfil {
  margin-top: 20px;
  padding: 20px 0;
  width: 100%;
}
.names-input {
  display: flex;
  align-items: flex-start;
}

.names-input-mobile {
  display: block;
  align-items: flex-start;
}

.names-input .item {
  display: block;
}
.names-input .item + .item {
  margin-left: 30px;
}
.names-input .item label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.names-input .item input {
  border: none;
  border: solid 1px var(--bordercolor);
  border-radius: 5px;
  height: 55px;
  padding: 20px;
  font-size: 14px;
  transition: 0.3s;
  width: 235px;
}
.names-input .item input:hover,
.names-input .item input:focus {
  border-color: var(--maincolor);
  background: var(--maincolortrans);
  box-shadow: 0px 4px 10px var(--maincolortrans);
}
.names-input .item input:focus {
  font-weight: 600;
}

.names-input-mobile .item {
  display: block;
}
.names-input-mobile .item + .item {
  margin-top: 20px;
}
.names-input-mobile .item label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 15px;
}
.names-input-mobile .item input {
  border: none;
  border: solid 1px var(--bordercolor);
  border-radius: 5px;
  height: 55px;
  padding: 20px;
  font-size: 14px;
  transition: 0.3s;
  width: 235px;
}
.names-input-mobile .item input:hover,
.names-input-mobile .item input:focus {
  border-color: var(--maincolor);
  background: var(--maincolortrans);
  box-shadow: 0px 4px 10px var(--maincolortrans);
}
.names-input-mobile .item input:focus {
  font-weight: 600;
}

.btn-atualizar-perfil {
  border: none;
  background: var(--maincolor);
  color: #fff;
  outline: none;
  font-weight: 600;
  height: 55px;
  padding: 0 42px;
  border-radius: 5px;
  margin-top: 20px;
}

.side-right {
  width: 100%;
}
.side-right img {
  margin-left: 120px;
  width: 300px;
}

.margin-to-student {
  margin: 0 40px;
  margin-top: 20px;
}
</style>
