<template>
  <div>
    <div class="container-search mt-3 mt-md-0">
      <div>
        <img src="@/assets/icons/search.svg" class="Pointer search-icon" />
        <input
          type="text"
          :placeholder="$t('locales.placeholder')"
          v-model="search"
          class="input-search"
        />
      </div>
    </div>
    <div class="country-container">
      <label
        class="label-country"
        v-for="list in filteredItems"
        :key="list.sigla"
      >
        <input
          type="radio"
          name="country"
          @click="changeCountry(list.sigla, list.pais)"
          :value="list.sigla"
        />
        <img
          :src="require(`@/assets/locales/flag-${list.sigla}.png`)"
          :alt="list.pais"
        />
      </label>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/main.js";
import MetaService from "@/services/resources/MetaService";
const service = MetaService.build();
import Cookies from "js-cookie";

import MemberMetaService from "@/services/resources/MemberMetaService";
const serviceMemberMeta = MemberMetaService.build();

export default {
  name: "LocalesV2",
  props: ["off"],
  data() {
    return {
      pais: "BR",
      paises: false,
      pais_nome: "BR | Português",
      pais_code: "BR",
      search: "",
      showSelectedImg: true,
      lists: [],
    };
  },
  computed: {
    filteredItems() {
      return !this.search.length
        ? this.lists
        : this.lists.filter(
            (item) =>
              item.pais
                .toLowerCase()
                .includes(this.search.toLowerCase().trim()) ||
              item.sub
                .toLowerCase()
                .includes(this.search.toLowerCase().trim()) ||
              item.sigla
                .toLowerCase()
                .includes(this.search.toLowerCase().trim())
          );
    },
  },
  mounted() {
    this.fetchCountry();
    this.$root.$on("getCountry", (data) => {
      this.fetchCountry();
    });
  },
  methods: {
    fetchCountry() {
      this.lists = [
        { sigla: "BR", sub: "Brazil", pais: "Brasil" },
        { sigla: "US", sub: "United States", pais: "United States" },
        { sigla: "ES", sub: "España", pais: "Spain" },
        { sigla: "CO", sub: "Colombia", pais: "Colombia" },
        { sigla: "MX", sub: "México", pais: "Mexico" },
      ];
      if (this.$route.path === "/minha-conta") {
        serviceMemberMeta
          .search("keys[]=pais")
          .then((resp) => {
            if (resp.pais !== undefined) {
              if (resp.pais === null) {
                this.selectCountry("BR");
              } else {
                var myArray = this.lists;
                var result = myArray.filter((x) => x.sigla === resp.pais);
                if (JSON.stringify(result) === "[]") {
                  this.setCountry("BR", "Brasil");
                } else {
                  this.setCountry(result[0].sigla, result[0].pais);
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        service
          .search("keys[]=pais")
          .then((resp) => {
            if (resp.pais !== undefined) {
              if (resp.pais === null) {
                this.selectCountry("BR");
              } else {
                var myArray = this.lists;
                var result = myArray.filter((x) => x.sigla === resp.pais);
                if (JSON.stringify(result) === "[]") {
                  this.setCountry("BR", "Brasil");
                } else {
                  this.setCountry(result[0].sigla, result[0].pais);
                }
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    setCountry(code, lang) {
      if (this.pais_code != code) {
        this.pais_code = code;
        this.pais_nome = `${code} | ${lang}`;
      }
      let data = {
        locale: code,
      };

      EventBus.$emit("atualizaPais", data);

      this.selectCountry(code);
      this.paises = false;
    },

    changeCountry(code, lang) {
      if (this.pais_code != code) {
        this.pais_code = code;
        this.pais_nome = `${code} | ${lang}`;
      }
      let data = {
        locale: code,
      };
      EventBus.$emit("atualizaPais", data);
      EventBus.$emit("SelectLocale", data);
      this.selectCountry(code);
      this.paises = false;

      if (this.$route.path === "/minha-conta") {
        serviceMemberMeta
          .postID({ id: "pais", value: code })
          .then((response) => {
            this.$bvToast.toast(this.$t("locales.msg"), {
              title: this.$t("locales.title"),
              variant: "success",
              autoHideDelay: 5000,
              appendToast: true,
            });
          });
      } else {
        service.postID({ id: "pais", value: code }).then((response) => {
          this.$bvToast.toast(this.$t("locales.msg"), {
            title: this.$t("locales.title"),
            variant: "success",
            autoHideDelay: 5000,
            appendToast: true,
          });
        });
      }
      this.showSelectedImg = true;
    },
    selectCountry(locale) {
      let permission = Cookies.get("access_level");
      if (permission !== "admin" && permission !== "owner") {
        this.search = "";
        if (locale === "BR") {
          this.$i18n.locale = "pt";
        } else if (
          locale == "AR" ||
          locale == "CO" ||
          locale == "CL" ||
          locale == "EC" ||
          locale == "GT" ||
          locale == "MX" ||
          locale == "PE" ||
          locale == "UY" ||
          locale == "ES"
        ) {
          this.$i18n.locale = "es";
        } else {
          this.$i18n.locale = "en";
        }
      }
    },
  },
  created() {
    EventBus.$on("changeLocale", (data) => {
      localStorage.setItem("location", data.locale);
      if (data.locale == "BR" || data.locale == "pt") {
        this.selectCountry("BR");
        this.pais_nome = "BR | Português";
        this.pais_code = "BR";
      } else if (
        data.locale == "AR" ||
        data.locale == "CO" ||
        data.locale == "CL" ||
        data.locale == "EC" ||
        data.locale == "GT" ||
        data.locale == "MX" ||
        data.locale == "PE" ||
        data.locale == "UY" ||
        data.locale == "ES"
      ) {
        this.selectCountry(data.locale);
        let pais = this.lists.filter((item) => item.sigla === data.locale);
        this.pais_nome = `${data.locale} | ${pais}`;
        this.pais_code = data.locale;
      } else {
        this.selectCountry("US");
        this.pais_nome = "US | English";
        this.pais_code = "US";
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.search-icon {
  position: relative;
  top: 31px;
}
.container-search {
  display: block;
  border-bottom: 1px solid var(--fontcolor2-v2);
  margin-bottom: 40px;
}
.country-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
}

.label-country {
  margin: 0px 30px 0px 0px;
}
.input-search {
  width: 100% !important;
  height: 40px !important;
  padding: 20px 30px;
  padding-left: 45px !important;
  font-family: Montserrat;
  font-size: 15px !important;
  color: #dfd4d4 !important;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 0;
}
.input-search::placeholder {
  font-family: Montserrat;
  font-size: 13px !important;
  color: #81858e !important;
}
.input-search:hover,
.input-search:focus {
  border-color: #ededf0 !important;
  font-weight: normal !important;
}
[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

[type="radio"] + img {
  cursor: pointer;
  width: 50px;
}

.container-paises-off {
  position: absolute !important;
  right: 0;
  top: -13px !important;
  color: var(--fontcolor);
  .item-pais img {
    width: 25px;
    height: 25px;
  }
  .paises {
    position: absolute !important;
    margin-left: -20px;
    color: var(--fontcolor);
  }
}
</style>
